import React, { Suspense } from 'react';
import '@fluentui/react/dist/css/fabric.css';
import './App.css';
import "flexboxgrid.css";
//import { hot } from 'react-hot-loader';
import { initializeIcons, loadTheme, ProgressIndicator } from '@fluentui/react';
import {BrowserRouter as Router} from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloakConfig from './keycloak';
//import LoginPage from './pages/Login/login';
import { AutoSwitchLayout } from './layout';
import routeConfig from './routeConfig';
import {NotificationsProvider, POSITIONS, setUpNotifications} from 'reapop';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '@vime/core/themes/default.css';
import NotificationPopup from 'components/NotificationPopup';
import { ThemeProvider, PartialTheme,Theme as ThemeV8 ,createTheme} from '@fluentui/react';
import { RenderRoutes } from 'route/RenderRoutes';
import { BrandVariants, FluentProvider, Theme, webLightTheme } from '@fluentui/react-components';
import { createv9Theme } from 'util/v9ThemeShim';
import { ChannelProvider } from 'util/useChannel';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation,FilePondPluginImagePreview,FilePondPluginFileValidateType,FilePondPluginFileValidateSize,FilePondPluginImageValidateSize, FilePondPluginMediaPreview);

const appTheme: ThemeV8 = createTheme ({
  palette: {
    themePrimary: '#ff9000',
    themeLighterAlt: '#fffbf5',
    themeLighter: '#ffedd6',
    themeLight: '#ffdeb3',
    themeTertiary: '#ffbd66',
    themeSecondary: '#ff9e1f',
    themeDarkAlt: '#e68200',
    themeDark: '#c26e00',
    themeDarker: '#8f5100',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
});

const customTheme:Theme =  createv9Theme(appTheme,webLightTheme);

initializeIcons();

setUpNotifications({
  defaultProps: {
      position: POSITIONS.topRight,
      dismissible: true,
      dismissAfter:3000,
      allowHTML: true,
  },
})

/*

            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
              
                <div className="ms-Grid-col ms-sm2 ms-xl2">
                  <Navigation />
                </div>
                <div className="main-element ms-Grid-col ms-sm10 ms-xl10">
                    <Router>
                      <Switch>
                        <Route exact path="/" component={HomePage}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <AuthRoute 
                          path={AuthRoutes.attributeSchema}
                          Component={AttributePage} 
                          requiredRoles={[String(UserRoles.ADMIN),String(UserRoles.SUPERADMIN)]} />
                      </Switch>
                    </Router>
                    
                </div>
              </div>
            </div>
    </ReactKeycloakProvider>

*/

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
}
//initOptions={keycloakProviderInitConfig}
function App() {
  const eventLogger = (event: unknown, error: unknown) => {
  }
  
  const tokenLogger = (tokens: unknown) => {
  }
  return (
    <ThemeProvider theme={appTheme}>
      <ChannelProvider>
        <FluentProvider  theme={customTheme}>
         <NotificationsProvider>
          <ReactKeycloakProvider
            authClient={keycloakConfig}
            
            onEvent={eventLogger}
            onTokens={tokenLogger}
          >
            <Router>
              <AutoSwitchLayout>
                <Suspense fallback={<ProgressIndicator label="Page loading..." />}>
                  {/*renderRoutes(routeConfig)*/}
                  <RenderRoutes routeConfig={routeConfig}/>
                </Suspense>
              </AutoSwitchLayout>
            </Router>
          </ReactKeycloakProvider>
          <NotificationPopup/>
        </NotificationsProvider>
        </FluentProvider>
        </ChannelProvider>
    </ThemeProvider>
  );
}


// Live reload != hot reload! CRA doesn't do hot reload, so we install it here.
//let HotApp = process.env.NODE_ENV === 'production' ? App : hot(module)(App);
//export default HotApp;
export default App;
